<template>
   <!--- 等待主持人开始当前会议 --->
    <van-dialog 
      v-model="showHostDialog"
      :confirmButtonText="$t('login.leaveMeet')"
      :before-close="beforeCloseHandle"
      class="win-host"
      >
      <van-loading color="#1DAF69" class="loading"></van-loading>
      <div class="title">{{ title }}</div>
      <div class="content">
        <div v-if="detail.beginTime && detail.type === 1">{{ $t('login.startTime')}}{{ detail.beginTime | formatTimeFilter('YYYY-MM-DD HH:mm')}}</div>
      <!--- bug 35512 要求去掉主持人登陆 因为没有登陆功能  :cancelButtonText="$t('home.signIn')" show-cancel-button--->
        <!-- <div>{{ detail.title }}</div>
        <div>{{$t('login.ifMain')}}</div> -->
      </div>
    </van-dialog>
</template>

<script>
import '@/utils/filter'
export default {
  data() {
    return {
       showHostDialog: false,
       waitHostTimer: null
    }
  },
  props: {
    showHostWin: Boolean,
    detail: Object,
    params: Object,
    title: String
  },
  watch: {
    showHostWin: {
      handler(value) {
        this.showHostDialog = value

        //弹窗不关闭 定时入会
        if (value) {
           this.waitHostTimer = setInterval(() => {
            console.error('waitHostTimer计时器再走')
            const { cb, params } = this.params
            cb && cb.call(this, ...params)
          }, 15000)
        } else {
          this.clearTimers()
        }
      },
      immediate: true
    } 
  },
  computed: {
    
  },
  mounted() {
  },
  
  methods: {
    clearTimers() { //清理计时器引用
      this.waitHostTimer && clearInterval(this.waitHostTimer)
      this.waitHostTimer = null
    },
    
    beforeCloseHandle(action, done) {
      if (action === 'confirm') {
        this.clearTimers()
        done()
        this.$emit('close')
      } else {
        this.$emit('close')
        this.$router.push({
          name: 'login'
        })
      }
    },
  },

  beforeDestroy() {
    this.clearTimers()
  }
};
</script>

<style lang="less" scoped>
.win-host {
     width: 580px;

     .loading {
       width: 80px;
       height: 80px;
       margin: 0 auto;
       margin-top: 50px;
     }

     .title {
        font-size: 36px;
        font-weight: 600;
        color: #000000;
        margin-top: 50px;
        text-align: center;
        padding-left:40px;
        padding-right:40px;
     }
     .content {
        font-size: 30px;
        font-weight: 400;
        color: #999999;
        line-height: 44px;
        margin-top: 30px;
        margin-bottom: 60px;
        padding-left:40px;
        padding-right:40px;
        text-align: center;
     }
} 

/deep/ .van-dialog {
  background: #FFFFFF;
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}
/deep/ .van-dialog__cancel {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
}
::v-deep .van-dialog__confirm, .van-dialog__confirm:active {
  color:#FF4D4F;
}
</style>
