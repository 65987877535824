<template>
   <!--- 入会密码弹窗 --->
    <van-dialog 
      v-model="showPwdDialog"
      :confirmButtonText="$t('login.login')"
      confirmButtonColor="#1DAF69"
      :before-close="beforeCloseHandle"
      class="win-pwd"
      show-cancel-button>
      <div class="title">{{ $t('login.meetPwd') }}</div>
      <van-field 
          :placeholder="$t('login.meetNo')" 
          class="pwd-input" 
          type="digit"
          :class="{'active': joinPassword }" 
          v-model="joinPassword" />
    </van-dialog>
</template>

<script>
import { checkSmallPwd  } from '@/utils/check'
export default {
  data() {
    return {
      joinPassword:'',
      showPwdDialog: false,
      isPwdError: true
    }
  },
  props: {
    showPwdWin: Boolean
  },
  watch: {
    showPwdWin: {
      handler(value) {
        if (value) {
          this.joinPassword = ''
        }
        this.showPwdDialog = value
      },
      immediate: true
    } 
  },
  computed: {
  },
  mounted() { 
    //错误码60012<加入会议密码错误> 的回调监听
    this.$eventBus.$on('join-password-error-handle', error => {
      this.isPwdError = true
      this.$toast(this.$t('login.pleaseTwo'))
    })
  },
  
  methods: {
    beforeCloseHandle(action, done) {
      if (action === 'confirm') {
        //校验
        if(!this.joinPassword) {
          this.$toast(this.$t('reg.emptyPwd'))
          return done(false)
        } else if(!checkSmallPwd(this.joinPassword)) {
          this.$toast(this.$t('reg.huiyiPwd'))
          return done(false)
        } else {
          this.isPwdError = false
          this.$emit('join-handle', this.joinPassword, null, () => {
              if (this.isPwdError) {
                return done(false)
              } else {
                done()
                this.$emit('close')
              }   
          })
        }
      } else {
        done()
        this.$emit('close')
      }
    },

  }
};
</script>

<style lang="less" scoped>
.win-pwd {
     width: 580px;

     .title {
        font-size: 36px;
        font-weight: 500;
        color: #000000;
        text-align: center;
        margin-top: 38px;
     }
      
     .pwd-input {
        margin: 0 auto;
        margin-top: 32px;
        width: 500px;
        height: 72px;
        line-height: 72px;
        padding: 0;
        padding-left: 15px;
        padding-right: 15px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 40px;
     }

     .pwd-input.active {
       border: 1px solid #1AB370;
     }

   } 

/deep/ .van-dialog {
  background: #FFFFFF;
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

/deep/ .pwd-input .van-field__control {
  color: #1AB370;
}
</style>
